<template>
	<div class="app-container" style="padding-top: 0">
		<div style="margin: 10px 0px">
			<el-button size="medium" type="text" style="font-size: 16px; color: #333" @click="$router.go(-1)">返回</el-button>
		</div>
		<el-tabs v-model="activeName">
			<el-tab-pane label="离职原因配置" name="1">
				<quitList ref="reasonLeave" :activeTab="activeName" v-if="activeName === '1'" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import quitList from '@/views/config/quitList';
// import orgConfig from '../orgConfig.vue';
export default {
	name: 'reasonLeave',
	components: { quitList },

	data() {
		return { activeName: '' };
	},
	mounted() {
		console.log(this.pageName, 'pageName');
		const data = JSON.parse(sessionStorage.getItem('reasonLeaveData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			'reasonLeaveData',
			JSON.stringify({
				activeName: this.activeName
			})
		);
		next();
	}
};
</script>

<style lang="scss" scoped></style>
