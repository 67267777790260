<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-29 09:56:56
 * @LastEditTime: 2020-05-06 18:57:57
 -->
<template>
	<div v-loading="loading" class="app-container">
		<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="修改时间">
				<el-date-picker
					v-model="searchForm.startTime"
					size="small"
					default-time="00:00:00"
					type="date"
					value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.endTime"
					size="small"
					default-time="23:59:59"
					type="date"
					value-format="yyyy-MM-dd HH:mm:ss"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
			</el-form-item>
			<el-button
				type="primary"
				size="small"
				class="fr"
				style="margin: 0px 0px 0px 10px"
				@click="_openEdit()"
				icon="el-icon-plus"
				v-if="permissionControlBtns(pageName, 'Add')"
				>添加</el-button
			>
			<el-button type="primary" size="small" style="margin-left: 8px; margin-top: 1px; height: 32px" @click="_getList(1)" icon="el-icon-search"
				>查询</el-button
			>
		</el-form>
		<el-table :data="list" stripe>
			<el-table-column v-for="(col, i) in columns" :prop="col.prop" :key="i" :label="col.label" align="center" show-overflow-tooltip>
				<template v-slot="{ row }" v-if="col.prop == 'updateTime'">
					{{ row.updateTime.slice(0, 10) }}
				</template>
				<template slot-scope="scope" v-else-if="col.prop == 'improvementMeasures'">
					<el-tooltip class="item" effect="dark" :content="scope.row.improvementMeasures" placement="top">
						<div class="moryeReason">{{ scope.row.improvementMeasures }}</div>
					</el-tooltip>
				</template>
			</el-table-column>
			<el-table-column align="center" label="操作" width="130">
				<template slot-scope="scope">
					<el-button type="text" @click="_openEdit(scope.row)" v-if="permissionControlBtns(pageName, 'Edit')">编辑</el-button>
					<el-button type="text" @click="_rowDelete(scope.row)" style="color: red" v-if="permissionControlBtns(pageName, 'Delete')"
						>删除</el-button
					>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			style="margin: 20px 0; text-align: right"
			@size-change="_handleSizeChange"
			@current-change="_handleCurrentChange"
			:current-page="searchForm.pageNo"
			:page-sizes="[20, 50, 100, 200]"
			:page-size="searchForm.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="searchForm.total"
		></el-pagination>
		<el-dialog :title="`${currentForm.id ? '编辑' : '新增'}离职原因`" center :visible.sync="editDialog" width="500px">
			<el-form label-width="90px" ref="currentForm" :rules="rules" :model="currentForm">
				<el-form-item label="离职原因" prop="reason">
					<el-input v-model="currentForm.reason"></el-input>
				</el-form-item>
				<el-form-item label="改善措施" prop="improvementMeasures">
					<el-input v-model="currentForm.improvementMeasures"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialog = false">取 消</el-button>
				<el-button type="primary" :loading="saveLoading" @click="_save">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { quitConfigList, quitConfigSave, quitConfigDelete } from '@/api/config.js';
export default {
	computed: {
		pageName() {
			return this.$route.name;
		}
	},
	data() {
		return {
			loading: false,
			saveLoading: false,
			editDialog: false,
			dateRange: '',
			searchForm: {
				startTime: '',
				endTime: '',
				pageNo: 1,
				pageSize: 20,
				total: 0
			},
			currentForm: {},
			list: [],
			columns: [
				{ prop: 'reason', label: '离职原因' },
				{ prop: 'improvementMeasures', label: '改善措施' },
				{ prop: 'operatorName', label: '操作人姓名' },
				{ prop: 'updateTime', label: '修改时间' }
			],
			rules: {
				reason: { required: true, message: '离职原因', trigger: 'blur' }
			}
		};
	},
	methods: {
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			this.loading = true;
			quitConfigList(this.searchForm).then((res) => {
				this.loading = false;
				this.list = res.list;
				this.searchForm.total = res.totalNum;
			});
		},
		_rowDelete(row) {
			this.$confirm(`您确认要删除${row.reason}离职原因`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					quitConfigDelete({ id: row.id }).then((res) => {
						if (res.code == 0) {
							this.$message.success('删除成功');
							this._getList();
						}
					});
				})
				.catch(() => {});
		},
		_openEdit(row) {
			this.currentForm = row ? { ...row } : {};
			this.editDialog = true;
			this.$nextTick(() => {
				this.$refs.currentForm.clearValidate();
			});
		},
		_save() {
			this.$refs['currentForm'].validate((valid) => {
				if (valid) {
					this.saveLoadin = true;
					quitConfigSave(this.currentForm).then((res) => {
						this.saveLoadin = false;
						if (res.code == 0) {
							this.$message.success(`${this.currentForm.id ? '编辑' : '新增'}成功`);
							this.editDialog = false;
							this._getList(1);
						}
					});
				}
			});
		}
	},
	mounted() {
		this._getList();
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
.moryeReason {
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}
</style>
