/*
 * @Author: 卢均锐morye
 * @Date: 2020-04-29 10:01:10
 * @LastEditTime: 2020-04-29 10:02:40
 */
//当月工资列表
import { post } from "./api";
//离职原因配置列表
export const quitConfigList = (params) => post('/v1/org/quit/config/list', { request: params });
//离职原因配置保存
export const quitConfigSave = (params) => post('/v1/org/quit/config/save', { request: params });
//离职原因配置删除
export const quitConfigDelete = (params) => post('/v1/org/quit/config/delete', { request: params });